    .react-grid-layout {
    position: relative;
    transition: height 200ms ease;


}
.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, right;
}

.react-grid-item .drag-handle {
    position: absolute;
    width: 100%;
    height: 25px;
    z-index: 1;
    cursor: pointer;
}

.react-grid-item img {
    pointer-events: none;
    user-select: none;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.dropping {
    visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    /*z-index: 2;*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
}

.react-resizable-hide > .react-resizable-handle {
    display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;

}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;

}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;

}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    margin-top: -10px;
    cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;

}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;

}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;

}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
}

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.react-grid-item {
    box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
    /*background: #ccc;*/
}
.react-grid-item.resizing {
    opacity: 0.9;
}
.react-grid-item.static {
    background: #cce;
}
.react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
}
.react-grid-item .minMax {
    font-size: 12px;
}
.react-grid-item .add {
    cursor: pointer;
}
.react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
}

.toolbox {
    background-color: #dfd;
    width: 100%;
    height: 120px;
    overflow: scroll;
}

.hide-button {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
}

.toolbox__title {
    font-size: 24px;
    margin-bottom: 5px;
}
.toolbox__items {
    display: block;
}
.toolbox__items__item {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    background-color: #ddd;
}
.droppable-element {
    width: 150px;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
}

.react-resizable-handle:after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
}


.react-draggable-transparent-selection *::-moz-selection {all: inherit;}
.react-draggable-transparent-selection *::selection {all: inherit;}
