.navbar {
	min-height: $navbar-height;
	padding: ($navbar-height - $nav-link-padding-y*2 - $font-size-base*$line-height-base)/2 1rem;
	display: -ms-flexbox;
    display: flex;
    .dropdown-menu-right{
	    left: auto;
	    right: 0;
	}
	svg{
		vertical-align: middle;
	}
	.nav-link{
		color: inherit;
    	white-space: nowrap;
	}
	.nav-active-border .nav-link{
		margin: -($navbar-height - $nav-link-padding-y*2 - $font-size-base*$line-height-base)/2 0;
    	line-height: $navbar-height - $navbar-padding-y*2;
   	}
	.dropdown-menu{
		margin-top: 0;
	}
	.bg-white &{
		.nav-item.active{
			color: $primary;
		}
	}
}

.toolbar{
	-ms-flex-negative: 0;
  	flex-shrink: 0;
  	display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
	margin: 0 -0.25rem;
	> *{
		margin: 0 0.25rem;
	}
	&.toolbar-mx{
		margin: 0 -0.5rem;
		> *{
			margin: 0 0.5rem;
		}
	}
}

.nav-title{
	font-size: 1.125rem;
	line-height: 1.2;
}

.navbar-brand{
	font-size: 1.125rem;
	line-height: 1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
    align-items: center;
	> span {
	    margin: 0 0.5rem;
	    font-weight: 600;
	}
	img,
	svg{
		max-height: $navbar-brand-height;
		vertical-align: middle;
		font-weight: 600;
	}
}

.navbar-menu{
	-ms-flex-direction: row;
    flex-direction: row;
    -ms-align-items: center;
	align-items: center;
	//margin-left: auto;
}