$full-black:  		rgba(0, 0, 0, 1);
$light-black: 		rgba(0, 0, 0, 0.35);
$min-black:			rgba(135, 150, 165, 0.1);
$full-white:  		rgba(255, 255, 255, 1);
$light-white: 		rgba(255, 255, 255, 0.35);
$min-white:			rgba(255, 255, 255, 0.1);

$primary:           #448bff;
$secondary:         #535c78;

$info:              #14bae4;
$success:           #31c971;
$warning:           #f4c414;
//$danger:            #f54394;
$danger:            #fb2640;

$light:             #f5f5f6;
$dark:              #192039;
$black:				#13182c;
$white:				#ffffff;

$body-bg:       	#f9f9fa;
$text-color:        #5e676f;
$text-muted:        #99a0ac;
$dark-color:        #b9c0d3;
$dark-muted:        #838aa0;

$border-color:	 	rgba(160, 175, 185, 0.15);
$border-color-lt:	rgba(160, 175, 185, 0.05);
$box-shadow:		0 1px 3px rgba(0,0,0,0.05);
$box-shadows: 		0 10px 25px 0 rgba(50, 50, 50, 0.15);
$card-box-shadow:   $box-shadow;
$color-percent:     1.75%;
$color-level:  		-10.5;

$font-family-base:			-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-serif:         Georgia, "Times New Roman", Times, serif;

$font-size-base:			0.875rem;
$line-height-base:			1.25/0.875;

$font-size-lg:            	2.5rem;
$font-size-md:              1.25rem;
$font-size-sm:            	0.825rem;

$border-radius-base:      	3px;

$navbar-height:                  3.75rem;
$navbar-brand-height:            2.5rem;
$navbar-brand-size: 			 1.125rem;

$aside-width:                    13.5rem;
$aside-slide-width:              17.5rem;
$aside-folded-width:             4.5rem;
$aside-folded-md-width:          5.5rem;
$aside-nav-height:               2.5rem;
$aside-nav-folded-height:        2.5rem;
$aside-nav-sub-height:           2rem;
$aside-nav-stacked-height:		 2rem;
$aside-nav-stacked-margin:       0.15rem;
$aside-nav-icon-margin:          0.5rem;
$aside-nav-icon-size:			 1rem;
$aside-nav-folded-icon-size:     1rem;

$table-border-color:             $border-color;
$table-striped-color:            rgba(0, 0, 0, 0.025);
$list-group-border:				 $border-color-lt;

//$input-border:                   rgba(135, 150, 165, 0.15);
$input-border:                   #ccc;
$input-border-width:             1px;
$input-border-focus:             $primary;
$input-border-radius:            0.25rem;

$switch-width:                   24px;
$switch-height:                  14px;
$switch-md-width:                32px;
$switch-md-height:               20px;
$switch-lg-width:                40px;
$switch-lg-height:               24px;

$ui-check-size:                  14px;
$ui-check-md-size:               20px;
$ui-check-lg-size:               24px;
$ui-check-sign-size:             6px;
$ui-check-sign-color:            $primary;
$ui-check-border-width:          1px;
$ui-check-border-radius:         4px;
$ui-check-border:        		 rgba(135, 150, 165, 0.35);

$badge-border-radius:			 0.15rem;

$list-hover-color:			     $info;
$list-row-padding-x:			 0.625rem;
$list-row-padding-y:			 0.75rem;
$list-content-padding:			 1rem;

$animate-start: 				 75%;
