// Colors
$white: #ffffff;
$black: #000000;
$red: #fb2640;
$pink: #e91e63;
$purple: #9c27b0;
$deep-purple: #673ab7;
$indigo: #3f51b5;
$blue: #448bff; //#2196f3
$light-blue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$light-green: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deep-orange: #ff5722;
$brown: #795548;
$blue-grey: #607d8b;
$grey: #9e9e9e;