@import "variables";

body {
  background: #f4f7fa;
}

.btn-primary {
  background-color: $red !important;
  border-color: $red !important;
}

.btn-upload {
  background-color: $blue !important;
  border-color: $blue !important;
  color: $white !important;
}

@media only screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }
}

.small {
  font-size: 0.825rem;
}

@media (max-width: 991.98px) {
  .navbar.navbar-expand-lg  {background: #fb2640; color: white;}
  .navbar.navbar-expand-lg i,
  .navbar.navbar-expand-lg button {
    color: #ffffff !important;

    &.dropdown-item {
      color: #212529 !important;
    }
  }
}

input.has-error,
textarea.has-error,
select.has-error {
  border-bottom: 1px solid $red;
}

.avatar {
  box-shadow: none;
}

.progress-bar {
  &.bg-danger {
    background-color: #FB2640 !important;
  }
}

.dark-fill {
  height: 100vh;
  overflow: auto;
}

// Homemade Tailwind

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.font-semibold {
  font-weight: 600;
}

.hover\:underline {
  &:hover {
   text-decoration: underline;
  }
}

