/*
Scrollable
*/
.scrollable{
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &.hover {
    overflow-y: hidden;
    > *{
      margin-top: -1px;
    }
    &:hover,
    &:focus,
    &:active{
      overflow: visible;
      overflow-y: auto;
    }
  }
  .touch & {
    overflow-y: auto !important;
  }
}

.scroll-x, 
.scroll-y{
  overflow:hidden;
  -webkit-overflow-scrolling:touch;
}
.scroll-y{
  overflow-y:auto;
}
.scroll-x{
  overflow-x:auto;
}

.no-scroll{
  overflow: hidden;
}
