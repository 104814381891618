/*
Page
*/
.page-bg{
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 0;
  &:before{
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    background-image: inherit;
    background-position: inherit;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.1;
  }
  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), $body-bg);
    background-repeat: repeat-x;
  }
  .bg-dark &{
    &:before{
      opacity: 0.15;
    }
    &:after{
      background-image: linear-gradient(to bottom, rgba(0,0,0,0), $dark);
    }
  }
}

.cover{
  background-size: cover;
  background-repeat: no-repeat;
  background-image: inherit;
  background-position: 50% 50%;
  position: relative;
  &:after{
    position: absolute;
    z-index: 0;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    background-color: inherit;
    opacity: 0.65;
  }
  &.cover-gd:after{
    background-image: linear-gradient(to right, $info, $primary);
  }
  > *{
    position: relative;
    z-index: 1;
  }
}

.swapimg{
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;
  .handler{
    cursor: move;
    position: absolute;
    top: -2.5%;
    right: -1.5rem;
    height: 105%;
    width: 3rem;
    &:after{
      content: "";
      position: absolute;
      height: 100%;
      width: 50%;
      border-right: 1px solid rgba(135, 150, 165, 0.1);
    }
  }
}

.browser{
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(135, 150, 165, 0.1);
  background-clip: padding-box;
  padding-top: 28px;
  border-radius: 4px;
  overflow: hidden;
  &:before{
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 8px rgba(135, 150, 165, 0.1), 14px 0 rgba(135, 150, 165, 0.1), 28px 0 rgba(135, 150, 165, 0.1);
    display: block;
    position: absolute;
    top: 10px;
    left: 8px;
  }
  &.colored:before{
    box-shadow: inset 0 0 0 8px #fc605c, 14px 0 #fdbc40, 28px 0 #34c84a;
  }
}
