/*
Plugins
*/

// fullcalendar
.fc-toolbar{
  margin-bottom: 0 !important;
  padding: 0.75rem 2rem;
  h2{
    font-size: 0.8rem;
    padding-top: 8px;
    margin-right: 8px;
    color: inherit;
  }
  button{
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    text-shadow: none;
    box-shadow: none;
  }
}
.fc-head th{
  box-shadow: $box-shadow;
}
.bg-dark .fc-head th{
  background-color: $dark;
  color: inherit;
}
.fc-event{
  border-width: 0;
  padding: 5px 8px;
  color: inherit;
}
.fc-event:hover{
  color: inherit;
}
.fc-event .fc-bg{
  background-color: transparent;
}
.fc td,
.fc tr,
.fc th{
  border-color: $border-color-lt;
}
.fc .popover{
  min-width: 220px;
}
.fc-content .fc-time{
  display: block;
  margin-bottom: 0.25rem;
  font-weight: normal !important;
}
.fc-content .fc-title{
  font-weight: 600;
}
.fc-day-number{
  font-size: 0.8em;
  opacity: 0.5;
}
td.fc-today{
  background: transparent !important;
}
.fc-today .fc-day-number{
  background: $primary;
  color: $full-white;
  opacity: 1;
  padding: 0px 3px;
}
.fc-today.fc-widget-header{
  color: $primary !important;
}
.fc-divider{
  border: none !important;
  background-color: $border-color-lt !important;
}
.fc-axis span{
  opacity: 0.6;
  font-size: 11px;
}
.fc-head-container.fc-widget-header,
.fc-body > tr > .fc-widget-content{
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.fc-widget-header th{
  text-align: left !important;
  padding: 12px;
}
.fc-widget-header .fc-week-title{
  display: block;
  font-weight: 400;
  font-size: 11px;
}
.fc-other-month{
  background-image: linear-gradient(45deg, rgba(120, 130, 140, 0.02) 25%, transparent 25%, transparent 50%, rgba(120, 130, 140, 0.02) 50%, rgba(120, 130, 140, 0.02) 75%, transparent 75%, transparent);
  background-size: 1.5rem 1.5rem;
}
.fc .fc-axis{
  border: 0;
}

.fc-header-toolbar,
.fc-head-container.fc-widget-header{
  background-color: #fff;
  .bg-dark &{
    background-color: transparent;
  }
}

.fc-scroller{
  overflow: visible !important;
}

/*JQVmap*/
div.jqvmap-zoomin, 
div.jqvmap-zoomout{
  width: 18px;
  height: 18px;
  background-color: #fff;
  color: #666;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/*Charts*/
.ct-label{
  color: rgba(130,140,155,0.65);
  fill: rgba(130,140,155,0.65);
}
.ct-grid{
  stroke: $border-color;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 2rem;
  padding: .25rem .5rem;
  background: rgba(0,0,0,0.8);
  border-radius: 3px;
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: opacity .2s linear;
}

.chartist-tooltip-meta{
  color: rgba(255,255,255,0.8);
}

.chartist-tooltip:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -4px;
    border: 4px solid transparent;
    border-top-color: rgba(0,0,0,0.8);
}
.chartist-tooltip.tooltip-show {
    opacity: 1;
}

.ct-area, .ct-line { pointer-events: none; }

.ct-stroke-1 .ct-line{
  stroke-width: 1px;
}
.ct-stroke-2 .ct-line{
  stroke-width: 2px;
}
.ct-stroke-3 .ct-line{
  stroke-width: 3px;
}
.ct-stroke-4 .ct-line{
  stroke-width: 4px;
}
.ct-stroke-5 .ct-line{
  stroke-width: 5px;
}

.ct-point-hover{
  .ct-point{
    transition: opacity 0.5s;
    opacity: 0 !important;
    &:hover,
    &:focus{
      opacity: 1 !important;
    }
  }
}

$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o);
$ct-series-colors: (
  #2196f3,
  #03a9f4,
  #00bcd4,
  #009688,
  #4caf50,
  #8bc34a,
  #cddc39,
  #ffeb3b,
  #ffc107,
  #ff9800,
  #ff5722,
  #795548,
  #607d8b,
  #9e9e9e,
  #ababab
);

@mixin ct-chart-series-color($color) {
  .ct-point, .ct-line, .ct-bar, .ct-slice-donut {
    stroke: $color;
  }
  .ct-slice-pie, .ct-slice-donut-solid, .ct-area{
    fill: $color;
  }
}

@for $i from 0 to length($ct-series-names) {
  .ct-series-#{nth($ct-series-names, $i + 1)} {
    $color: nth($ct-series-colors, $i + 1);
    @include ct-chart-series-color($color);
  }
}

@for $i from 1 to 5 {
  .ct-stroke-#{$i} {
    .ct-line{
      stroke-width: #{$i}px;
    }
    .ct-bar,
    .ct-point{
      stroke-width: #{$i*2}px;
    }
  }
}

// apex
.apexcharts-tooltip.light,
.apexcharts-menu,
.apexcharts-xaxistooltip{
  color: #222;
  background-clip: padding-box !important;
  border: 1px solid rgba(19, 24, 44, 0.15) !important;
}
.apexcharts-xaxistooltip:before{
  border-bottom-color: 1px solid rgba(19, 24, 44, 0.15) !important;
}
.apexcharts-legend-text{
  color: inherit;
}
.apexcharts-gridline{
  stroke: rgba(130,140,155,0.15);
}
.apexcharts-yaxis text,
.apexcharts-xaxis text{
  fill: rgba(130,140,155,0.65);
}

// select
.bg-dark .btn-default.form-control{
  background: transparent !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: inherit !important;
}
.ui-select-bootstrap > .ui-select-choices{
  overflow-y: scroll;
}

// dataTable
.dataTables_wrapper{
  padding: 0;
}

// bootstrap table
.bootstrap-table{
  .fixed-table-loading{
    display: none;
    position: absolute;
    top: 42px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    text-align: center;
  }
  .fixed-table-toolbar{
    i{
      display: inline-block;
      min-height: 14px;
    }
  }
}

// summernote
.note-editor{
  border-width: 0 !important;
  overflow: visible !important;
  background-color: inherit;
  margin: 0;
}
.note-editor .note-editing-area .note-editable,
.note-editor .note-statusbar{
  background-color: transparent !important;
  color: inherit !important;
}
.note-toolbar{
  border-width: 0;
}
.note-editor .btn-light{
  background: transparent !important;
  border: none !important;
  color: inherit !important;
  opacity: 0.5;
}
.note-editor .btn-light.active{
  opacity: 1;
}
.note-editor-inline{
  .note-editor{
    box-shadow: none;
  }
  .note-editing-area{
    border: 1px solid $input-border;
    background: #fff;
  }
  .bg-dark &{
    .note-editing-area{
      background: transparent;
    }
  }
}

// select2
.select2-container--default {
  .select2-selection {
    min-height: 33px;
    border-color: $input-border !important;
    .bg-dark &{
      background-color: lighten( $dark, 1% );
    }
    .select2-selection__rendered{
      color: inherit;
      padding-left: 9px;
    }
    .select2-selection__arrow{
      top: 3px;
    }
    .select2-selection__choice{
      background: transparent;
      border-color: $input-border;
      line-height: 1.5;
    }
    .select2-selection__choice__remove{
      color: inherit !important;
    }
  }
  .select2-selection--single{
    .select2-selection__rendered{
      line-height: 30px;
    }
  }
  .select2-search--inline {
    .select2-search__field{
      margin-top: 6px;
    }
  }
  .select2-search__field{
    color: inherit;
  }
  .select2-search--dropdown{
    .select2-search__field{
      border-color: $input-border;
    }
  }
  .select2-dropdown{
    color: $text-color;
    border-color: $input-border;
  }
}

// datepicker
.datepicker{
  width: auto;
  padding: 0;
  font-size: 0.7rem;
  &.dropdown-menu{
    padding: 4px;
  }
  table{
    width: 100%;
    tr{
      td span.active,
      td.active,
      td.selected,
      td.today{
        &,
        &:hover,
        &:active{
          background-image: none;
          color: $full-white;
          background-color: $primary !important;
        }
      }
    }
    td,
    th{
      height: auto;
      line-height: 1.5 !important;
      border-width: 0;
      vertical-align: middle;
      padding: 4px !important;
      span:not(.active),
      &:not(.active):hover{
        background-color: transparent;
      }
      span{
        height: auto !important;
        line-height: 2 !important;
      }
    }
    .disabled{
      opacity: 0.4;
    }
  }
}
.daterangepicker{
  color: #333;
  .ranges li.active{
    background-color: $primary;
  }
}

// notie
.notie-container{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1050;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  padding: 0.875rem 1.5rem;
  background-color: $black;
  color: $full-white;
  border-radius: 2px;
  max-width: 35rem;
  min-width: 18rem;
  .btn{
    padding: 0px 6px;
    line-height: 18px;
    cursor: pointer;
    box-shadow: none !important;
  }
  .btn:empty{
    display: none;
  }
}
.notie-textbox{
  -ms-flex: 1;
  flex: 1;
  margin-right: 1.5rem;
}
.notie-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(255,255,255,0);
}
