#aside {
  //font-size: 24px;

  .zmdi {
    //font-size: 2rem !important;
  }

  .navbar {
    //padding: 1rem 1.25rem;
  }

  .navbar-brand {
    //font-size: 28px;
  }

  .nav-header {
    // font-size: 20px;
  }
}

#bonti-container {
  font-size: 36px;
  font-weight: bold;

  .progress {
    height: 4rem;
  }

  .progress-bar {
    font-size: 34px;
  }

  h2 {
    font-size: 36px !important;
  }

  h3 {
    font-size: 36px !important;
  }

  .w-96 {
    height: 96px;
  }

  .avatar.gd-success {
    font-size: 30px;
    font-weight: 700;
  }

  .progress {
    //height: 2rem;
  }

  .progress-bar {
    font-size: 14px;
    text-shadow: -1px 0 #ccc, 0 1px #ccc, 1px 0 #ccc, 0 -1px #ccc;
  }

  .fade-enter #bonti-container {
    opacity: 0;
    //transform: translateX(-100%);
  }
  .fade-enter-active #bonti-container {
    opacity: 1;
    //transform: translateX(0%);
  }
  .fade-exit #bonti-container {
    opacity: 1;
    //transform: translateX(0%);
  }
  .fade-exit-active #bonti-container {
    opacity: 0;
    //transform: translateX(100%);
  }
  .fade-enter-active #bonti-container,
  .fade-exit-active #bonti-container {
    transition: opacity 500ms, transform 500ms;
  }
}