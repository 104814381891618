/*
Base
*/
.pos-rlt{
  position: relative;
}
.pos-abt{
  position: absolute;
}
.pos-fix{
  position: fixed !important;
}
.pos-stc{
  position: static !important;
}

.active > .d-inline, 
.active > .auto .d-inline{
  display: none !important;
}
.active > .d-none, 
.active > .auto .d-none{
  display: inline-block !important;
}

.box-shadow{
  box-shadow: $box-shadow;
}

.box-shadows{
  box-shadow: $box-shadows;
}

.hide {
  display: none !important;
}

.hidden{
  visibility: hidden;
}

.show-rtl{
  display: none !important;
}

.pointer{
  cursor: pointer;
}

.circle{
  border-radius: 500px;
}

.clear{
  display:block;
  overflow: hidden;
}

.no-wrap{
  white-space: nowrap;
}

.no-shadow{
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.no-select{
  pointer-events: none;
  user-select:none;
}

.p-2-3{
  padding: 0.75rem;
}

.p-3-4{
  padding: 1.25rem;
}

.scale{
  transform: scale(0.9);
}

.scale-75{
  transform: scale(0.75);
}

.scale-50{
  transform: scale(0.5);
}

.scale-25{
  transform: scale(0.25);
}

@include media-breakpoint-down(sm) {
  .pos-stc-sm{
    position: static !important;
  }
}

@include media-breakpoint-down(xs) {
  .pos-stc-xs{
    position: static !important;
  }
}
