@import "./src/containers/variables";

.pagination {
  .active {
    .page-link,
    a {
      background-color: $red !important;
      border-color: $red !important;
    }
  }
}

.page-item {
  &.disabled {
    color: #B3B6B9 !important;
    cursor: not-allowed;
  }
}