@import "../../containers/variables";

#latest-activity {
  .comment {

    textarea {
      &:focus {
        border-color: #ddd;
        box-shadow: none;
      }
    }

    button {
      border-top-left-radius: 0;
      -webkit-border-top-left-radius: 0;
      border-top-right-radius: 0;
      -webkit-border-top-right-radius: 0;
    }
  }
}