/*
Checkbox and Radio
*/
.ui-check{
  position: relative;
  cursor: pointer;
  input{
    opacity: 0;
    position: absolute;
    z-index: -1;
    &:checked + i{
      background-color: $primary;
      box-shadow: none;
      &:before{
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) rotate(-45deg);
        width: $ui-check-sign-size;
        height: $ui-check-sign-size;
        background-color: #fff;
      }
    }
    &:checked + span .active{
      display: inherit;
    }
    &[type="radio"] + i{
      &,
      &:before{
        border-radius: 50%;
      }
    }
    &[type="checkbox"]:checked + i:before{
      transform: translate(-50%,-65%) rotate(-45deg);
      background-color: transparent;
      width: $ui-check-sign-size + 2;
      height: $ui-check-sign-size - 1;
      border-width: 0 0 2px 2px;
      border-color: #fff;
      border-style: solid;
    }
    &[type="radio"]:checked + i:before{
      
    }
    &[disabled],
    fieldset[disabled] & {
      & + i{
        border-color: $input-border;
        opacity: 0.35;
        &:before{
          background-color: $input-border;
        }
      }
    }
  }
  > i{
    width: $ui-check-size;
    height: $ui-check-size;
    line-height: 1;
    box-shadow: 0 0 0 $ui-check-border-width $ui-check-border;
    border-radius: $ui-check-border-radius;
    display: inline-block;
    vertical-align: middle;
    background-clip: padding-box;
    position: relative;
    left: 0;
    margin-right: 0.3rem;
    &:before{
      content:"";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0px;
      height: 0px;
      background-color: transparent;
      border-radius: 1px;
    }
  }
  &.is-invalid > i{
    box-shadow: inset 0 0 0 2px $danger;
  }
  &.is-valid > i{
    background-color: $success;
  }
  > span{
    margin-left: -20px;
    .active{
      display: none;
    }
  }
  &.ui-check-color{
    i{
      box-shadow: none;
    }
    input:checked + i:before {
      background-color: #fff;
    }
  }
  &.ui-check-rounded{
    > i{
      border-radius: 50%;
    }
  }
}

.ui-check-md{
  input{
    &:checked + i{
      &:before{
        width: $ui-check-sign-size + 2;
        height: $ui-check-sign-size + 2;
      }
    }
    &[type="checkbox"]:checked + i:before{
      width: $ui-check-sign-size + 6;
      height: $ui-check-sign-size;
    }
  }
  > i{
    width: $ui-check-md-size;
    height: $ui-check-md-size;
  }
}

.ui-check-lg{
  input{
    &:checked + i{
      &:before{
        width: $ui-check-sign-size + 4;
        height: $ui-check-sign-size + 4;
      }
    }
    &[type="checkbox"]:checked + i:before{
      width: $ui-check-sign-size + 8;
      height: $ui-check-sign-size + 2;
      border-width: 0 0 3px 3px;
    }
  }
  > i{
    width: $ui-check-lg-size;
    height: $ui-check-lg-size;
  }
}
