/*
Alert
*/
.close{
	color: inherit;
	text-shadow: none;
	&:hover,
	&:focus{
		color: inherit;
	}
}
