@import "../../containers/variables";

#campaign-container {
  .DayPickerInput {
    display: block !important;
  }

  .nav-link {
    &.disabled {
      opacity: 0.5;

      &:hover {
        color: $white;
      }
    }
  }
}