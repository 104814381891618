@import "../../containers/variables";

.loading-container {
  i.zmdi-view-dashboard {
    color: $red;
    font-size: 22px;
    margin: 0 5px;
  }
  .navbar-brand {
    color: $red;
  }

  .spinner-border {
    color: $red;
  }
}