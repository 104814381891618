/*
Aside 
*/

.aside{
  opacity: 1 !important;
  display: -ms-flexbox !important;
  display: flex !important;
  position: relative;
  z-index: 1000;
  &.show{
    width: 100% !important;
    top: 0;
    bottom: 0;
    > *{
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  > *{
      transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  > .modal-dialog{
    margin: 0;
    max-width: inherit;
    pointer-events: inherit;
  }

  &.aside-right{
    > *{
      right: 0;
      left: auto;
    }
    &.show{
      > *{
        transform: translate3d(0%, 0, 0) !important;
      }
    }
  }
}

.aside-deck{
  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: row;
  flex-direction: row;

  -ms-flex-align: end;
  align-items: flex-end;

  -ms-flex-pack: end;
  justify-content: flex-end;

  position: fixed;
  right: 0;
  bottom: 0;
  max-height: 80vh;
  z-index: 1050;
}

@include media-breakpoint-down(lg) {
  .aside-lg{
    display: none;
    opacity: 0;
    position: fixed !important;
    z-index: 1050 !important;
    left: 0;
    > *{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 280px !important;
      transform: translate3d(-100%, 0, 0);
    }
    ~ *{
      width: 100%;
    }
    &.aside-right{
      > *{
        transform: translate3d(100%, 0, 0);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .aside-md{
    display: none;
    opacity: 0;
    position: fixed !important;
    background: transparent !important;
    z-index: 1050 !important;
    > *{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 280px !important;
      transform: translate3d(-100%, 0, 0);
    }
    ~ *{
      width: 100%;
    }
    &.aside-right{
      > *{
        transform: translate3d(100%, 0, 0);
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .aside-sm{
    display: none;
    opacity: 0;
    background: transparent !important;
    position: fixed !important;
    z-index: 1050 !important;
    > *{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 280px !important;
      transform: translate3d(-100%, 0, 0);
    }
    ~ *{
      width: 100%;
    }
    &.aside-right{
      > *{
        transform: translate3d(100%, 0, 0);
      }
    }
  }
}
